var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ads._id)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
      callback: _vm.visibilityChanged,
      intersection: {
        threshold: 0.8
      },
      throttle: 600,
      throttleOptions: {
        leading: 'visible'
      }
    }),expression:"{\n      callback: visibilityChanged,\n      intersection: {\n        threshold: 0.8\n      },\n      throttle: 600,\n      throttleOptions: {\n        leading: 'visible'\n      }\n    }"}],staticClass:"mb-4",attrs:{"width":"100%"}},[_c('v-card-title',{staticStyle:{"font-size":"12px"}},[_c('editable-avatar',{staticClass:"mr-5",attrs:{"image":_vm.ads.business_image,"editable":false,"size":40}}),_vm._v(" "+_vm._s(_vm.ads.business_name)+" "),_c('span',{staticStyle:{"font-weight":"200"}},[_vm._v("'s advertisement")])],1),(_vm.ads.type == 'image')?_c('v-img',{attrs:{"src":_vm.ads.file,"alt":"ads","cover":""},on:{"click":_vm.goToWebsite}}):(_vm.ads.type == 'video' && _vm.ads.file)?_c('div',[_c('div',{staticClass:"position-relative d-flex justify-center align-center"},[_c('vue-plyr',{ref:"plyr",attrs:{"options":{
            autoplay: false,
            muted: true,
            autopause: true,
            controls: [
              'play',
              'progress',
              'current-time',
              'mute',
              'volume',
              'fullscreen'
            ]
          }}},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":"","id":"videoElement"},on:{"ended":_vm.onEnded}},[_c('source',{attrs:{"src":_vm.ads.file,"type":"video/mp4"}})])]),_c('div',{staticClass:"absolute-center"},[(hover)?_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":_vm.goToWebsite}},[_c('v-icon',[_vm._v("mdi-link")])],1):_vm._e()],1)],1)]):_vm._e(),_c('v-card-text',{staticClass:"text-block",on:{"click":_vm.goToWebsite}},[_vm._v(" "+_vm._s(_vm.ads.text)+" ")])],1)]}}],null,false,1637415284)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
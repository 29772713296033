<template>
  <div class="right-section">
    <div class="d-flex flex-column" style="width: 320px">
      <ads-viewer class="mt-5" type="video" />
      <ads-viewer class="mt-2" type="image" />
    </div>
    <div class="mt-10 d-flex flex-column">
      <div class="white--text url-button font-10" @click="goToAboutUs">
        About us
      </div>
      <div class="white--text url-button font-10" @click="goToPrivacy">
        Privacy and policy
      </div>
      <div class="white--text url-button font-10" @click="goToTOS">
        Terms of service
      </div>
      <div class="white--text font-10 mt-5 font-bold">
        © 2023 aKinder™Volunteer
      </div>
      <div class="white--text font-10 mb-15">All right reserved</div>
    </div>
  </div>
</template>
<script>
import AdsViewer from "@/components/ads/AdsViewer.vue";
export default {
  components: { AdsViewer },
  methods: {
    goToAboutUs() {
      this.$router.push({ name: "aboutus" });
    },
    goToPrivacy() {
      this.$router.push({ name: "privacy" });
    },
    goToTOS() {
      this.$router.push({ name: "terms-of-service" });
    }
  }
};
</script>
<style scoped>
.right-section {
  max-height: 0;
  overflow-y: hidden;
  position: sticky;
  min-height: inherit;
  overflow-x: hidden;
  top: 56px;
  min-width: 400px;
  max-width: 400px;
  padding-left: 12px;
}
.right-section:hover {
  overflow-y: auto;
}
.right-section::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.right-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.right-section::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.right-section::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>

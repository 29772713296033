<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit;"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit;"
        class="d-flex flex-row"
      >
        <side-menu class="hidden-sm-and-down" />
        <feed-section />
        <right-section class="hidden-md-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import FeedSection from "../event_feeds/FeedSection.vue";
import SideMenu from "./home/AdminHomeSideMenu";
import RightSection from "./AdminRightSection.vue";
export default {
  components: { FeedSection, SideMenu, RightSection },
  data() {
    return {
      snackbar: false,
      errorMessage: null
    };
  },
  methods: {
    onSelectEvent(event) {
      console.log(event);
    }
  }
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 50px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
</style>
